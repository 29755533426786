<template>
  <div>
    <!-- <this-header /> -->
    <div class="container-sm-lot pl-1 pr-1">
      <div class="top-bxt my-1">
        <img
          src="/icon/back.svg"
          alt="back"
          class="cursor-pointer ts-left"
          @click="$router.go(-1)"
        >

        <h3 class="text-primary text-primary mb-0">
          ติดต่อเจ้าหน้าที่
        </h3>
      </div>
      <div>
        <div>
          <a
            :href="contact"
            target="_blank"
            class="my-2"
          >
            <div class="card-contact">
              <div class="text-center">
                <img
                  src="@/assets/images/newIcon/contactus.png"
                  alt="card"
                  height="100"
                >
                <h4 class="mb-0 mt-75 text-primary">แจ้งปัญหาที่คุณพบ</h4>
                <p class="mb-0 text-primary">แอดมินตอบกลับตลอด 24 ชั่วโมง</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>

    <ThisFooter />
    <!-- </div> -->
  </div>
</template>

<script>
// import ThisHeader from '../components/ThisHeader.vue'
import ThisFooter from './components/ThisFooter.vue'

export default {
  name: 'Home',
  metaInfo: {
    title: 'ติดต่อเจ้าหน้าที่',
  },
  components: {
    // ThisHeader,
    ThisFooter,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      contact: null,
    }
  },
  created() {
    if (!this.userData) {
      this.$router.push({ name: 'home' })
    }
  },
  mounted() {
    this.GetContact()
  },
  methods: {
    GetContact() {
      this.$http.get('/GetContact').then(res => {
        this.contact = res.data.linecontact
      })
    },
  },
}
</script>

  <style scoped>
</style>
